.splash-animation {
  animation: splash 0.8s ease-in-out;
}

@keyframes splash {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  90% {
    opacity: 0.9;
    transform: translateY(-5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-animation {
  animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.blur-in-animation {
  animation: blur-in 0.2s ease-in-out;
}

@keyframes blur-in {
  0% {
    opacity: 0;
    backgdrop-filter: blur(0px);
  }
  100% {
    opacity: 1;
    backgdrop-filter: blur(10px);
  }
}

.fade-out-animation {
  animation: fade-out 0.5s ease-in-out;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

.zoom-in-animation {
  animation: zoom-in 0.4s ease-in-out;
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.zoom-out-animation {
  animation: zoom-out 0.4s ease-in-out;
}

@keyframes zoom-out {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.zoom-on-hover {
  transition:
    transform 0.2s ease-in-out,
    background-size 1s ease;
}

.zoom-on-hover:hover {
  transform: scale(1.05);
}

.slide-left-animation {
  animation: slide-left 0.5s ease-in-out forwards;
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-right-animation {
  animation: slide-right 0.5s ease-in-out forwards;
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-left-exit-animation {
  animation: slide-left-exit 0.5s ease-in-out forwards;
}

@keyframes slide-left-exit {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.slide-right-exit-animation {
  animation: slide-right-exit 0.5s ease-in-out forwards;
}

@keyframes slide-right-exit {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
