.app-button {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  align-items: center;
  height: 1.75rem;
  padding-left: .5rem;
  padding-right: .5rem;
  transition: background .2s ease-in-out;
  overflow: hidden;
}

.app-button:disabled {
  opacity: .5;
  background: #d3d3d3;
  cursor: not-allowed !important;
}

button:disabled {
  cursor: not-allowed;
}

@media (width <= 900px) {
  .app-button {
    font-size: .8rem;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --tech-blue: #00467c;
  --tech-red: #b71c1c;
  --tech-green: #1b5e20;
  --tech-yellow: #f57f17;
  --tech-orange: #e65100;
  --tech-purple: #4a148c;
  --tech-pink: #880e4f;
  --tech-gray: #616161;
  --tech-black: #212121;
  --tech-white: #fff;
  --broken-white: #f5f5f5;
  --light-orange: #ffcc80;
  --light-red: #ef9a9a;
  --light-green: #a5d6a7;
  --light-blue: #90caf9;
  --warm-white: #f8fafa;
  --digital-black: #00091e;
  --grey-bsc: #586061;
  --digital-blue: #001b60;
  --bsc-teal: #026d7c;
  --vintage-code: #00a9ae;
  --pop-code: #00cfbf;
  --flash-green: #0fc;
  --waring-orange: #ff7d00;
  --danger-red: #ff2e00;
  --teal-white: #ffffffb3;
  --shadow: #0003;
  --softer-blue: #008eae;
  --white-background: #f5f5f5;
  --digital-grey-I: #213539;
  --digital-grey-II: #2c464c;
  --digital-grey-III: #41646d;
  --digital-grey-IV: #628189;
  --digital-grey-V: #bccccb;
  --soft-grey: #eee;
  --red-error: #fd1e46;
  --grey-white: #f8fafa;
  --gradient-blue: linear-gradient(145deg, var(--softer-blue) 10%, var(--tech-blue) 80%, var(--digital-blue) 110%);
  --gradient-orange: linear-gradient(145deg, var(--light-orange) 10%, var(--waring-orange) 80%, var(--tech-orange) 110%);
  --gradient-red: linear-gradient(145deg, var(--light-red) 1%, var(--danger-red) 30%, var(--tech-red) 100%);
  --gradient-gray: linear-gradient(145deg, var(--grey-bsc) 1%, var(--tech-gray) 30%, var(--tech-black) 100%);
  --gradient-gray-light: linear-gradient(145deg, var(--broken-white) 20%, var(--tech-gray) 100%);
  --gradient-black: linear-gradient(315deg, #2b4162 0%, #12100e 74%);
  --background-gradient: linear-gradient(120deg, var(--teal-white) 0%, var(--tech-gray) 200%);
}

:root[data-theme="dark"] {
  --tech-blue: #00467c;
  --tech-red: #ef5350;
  --tech-green: #66bb6a;
  --tech-yellow: #ffb74d;
  --tech-orange: #ff7043;
  --tech-purple: #9c27b0;
  --tech-pink: #e91e63;
  --tech-gray: #bdbdbd;
  --tech-black: #000;
  --tech-white: #fff;
  --broken-white: #f5f5f5;
  --light-orange: #ffcc80;
  --light-red: #ef9a9a;
  --light-green: #a5d6a7;
  --light-blue: #90caf9;
  --warm-white: #f8fafa;
  --digital-black: #00091e;
  --grey-bsc: #586061;
  --digital-blue: #001b60;
  --bsc-teal: #026d7c;
  --vintage-code: #00a9ae;
  --pop-code: #00cfbf;
  --flash-green: #0fc;
  --waring-orange: #ff7d00;
  --danger-red: #ff2e00;
  --teal-white: #ffffffb3;
  --shadow: #00a9ae33;
  --softer-blue: #008eae;
  --digital-grey-I: #213539;
  --digital-grey-II: #2c464c;
  --digital-grey-III: #41646d;
  --digital-grey-IV: #628189;
  --digital-grey-V: #bccccb;
  --red-error: #fd1e46;
  --grey-white: #f8fafa;
}

.\!root {
  background: var(--grey-white) !important;
}

.root {
  background: var(--grey-white);
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.left-1\/2 {
  left: 50%;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.m-auto {
  margin: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.me-2:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.me-2:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.me-2:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.\!block {
  display: block !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.\!hidden {
  display: none !important;
}

.hidden {
  display: none;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-28 {
  max-height: 7rem;
}

.max-h-\[85\%\] {
  max-height: 85%;
}

.min-h-\[60px\] {
  min-height: 60px;
}

.w-1 {
  width: .25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[90px\] {
  width: 90px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.min-w-\[180px\] {
  min-width: 180px;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[50px\] {
  min-width: 50px;
}

.min-w-\[90px\] {
  min-width: 90px;
}

.min-w-full {
  min-width: 100%;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[260px\] {
  max-width: 260px;
}

.max-w-\[350px\] {
  max-width: 350px;
}

.max-w-\[380px\] {
  max-width: 380px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-\[60\%\] {
  max-width: 60%;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.origin-top-left {
  transform-origin: 0 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-auto {
  cursor: auto;
}

.cursor-copy {
  cursor: copy;
}

.cursor-default {
  cursor: default;
}

.cursor-help {
  cursor: help;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-auto {
  -webkit-user-select: auto;
  user-select: auto;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-\[100px_auto\] {
  grid-template-columns: 100px auto;
}

.grid-cols-\[2rem\,2fr\] {
  grid-template-columns: 2rem 2fr;
}

.grid-cols-\[2rem\,2rem\,2rem\,2fr\] {
  grid-template-columns: 2rem 2rem 2rem 2fr;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-stretch {
  justify-content: stretch;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[2px\] {
  gap: 2px;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-\[-1px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-all {
  word-break: break-all;
}

.break-keep {
  word-break: keep-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-none {
  border-style: none;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(187, 247, 208, var(--tw-border-opacity));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(74, 222, 128, var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 252, 231, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 247, 208, var(--tw-bg-opacity));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}

.bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 186, 116, var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.object-contain {
  object-fit: contain;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[60px\] {
  font-size: 60px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.\!text-orange-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(251, 146, 60, var(--tw-text-opacity)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(34, 197, 94, var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(21, 128, 61, var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(168, 85, 247, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.background-gradient {
  background: var(--background-gradient);
}

.black-gradient {
  background-color: #090947;
  background-image: linear-gradient(315deg, #090947 0%, #5a585a 74%);
}

.animated-gradient {
  transition: background-size 1s, transform .2s ease-in-out;
  background-size: 100% 100% !important;
}

.animated-gradient:hover {
  animation: 15s infinite gradient;
  background-size: 400% 400% !important;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

@media (width >= 640px) {
  .sm\:inline {
    display: inline;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

.splash-animation {
  animation: .8s ease-in-out splash;
}

@keyframes splash {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  90% {
    opacity: .9;
    transform: translateY(-5%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-animation {
  animation: .5s ease-in-out fade-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  90% {
    opacity: .9;
  }

  100% {
    opacity: 1;
  }
}

.blur-in-animation {
  animation: .2s ease-in-out blur-in;
}

@keyframes blur-in {
  0% {
    opacity: 0;
    backgdrop-filter: blur(0px);
  }

  100% {
    opacity: 1;
    backgdrop-filter: blur(10px);
  }
}

.fade-out-animation {
  animation: .5s ease-in-out fade-out;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  90% {
    opacity: .9;
  }

  100% {
    opacity: 0;
  }
}

.zoom-in-animation {
  animation: .4s ease-in-out zoom-in;
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.zoom-out-animation {
  animation: .4s ease-in-out zoom-out;
}

@keyframes zoom-out {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.zoom-on-hover {
  transition: transform .2s ease-in-out, background-size 1s;
}

.zoom-on-hover:hover {
  transform: scale(1.05);
}

.slide-left-animation {
  animation: .5s ease-in-out forwards slide-left;
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-right-animation {
  animation: .5s ease-in-out forwards slide-right;
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-left-exit-animation {
  animation: .5s ease-in-out forwards slide-left-exit;
}

@keyframes slide-left-exit {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.slide-right-exit-animation {
  animation: .5s ease-in-out forwards slide-right-exit;
}

@keyframes slide-right-exit {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

html, body {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-BoldItalic.d52ba8f5.eot");
  src: url("../Poppins-BoldItalic.d52ba8f5.eot#iefix") format("embedded-opentype"), url("../Poppins-BoldItalic.227f856a.woff2") format("woff2"), url("../Poppins-BoldItalic.87ac7736.woff") format("woff"), url("../Poppins-BoldItalic.3ed3aa5e.ttf") format("truetype"), url("../Poppins-BoldItalic.08f95b28.svg#Poppins-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-BlackItalic.376eee4b.eot");
  src: url("../Poppins-BlackItalic.376eee4b.eot#iefix") format("embedded-opentype"), url("../Poppins-BlackItalic.ab6a443c.woff2") format("woff2"), url("../Poppins-BlackItalic.fc9d4b94.woff") format("woff"), url("../Poppins-BlackItalic.dde6d0dc.ttf") format("truetype"), url("../Poppins-BlackItalic.b8c9ba8a.svg#Poppins-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-Bold.34d85f4b.eot");
  src: url("../Poppins-Bold.34d85f4b.eot#iefix") format("embedded-opentype"), url("../Poppins-Bold.28c34a19.woff2") format("woff2"), url("../Poppins-Bold.98868abe.woff") format("woff"), url("../Poppins-Bold.6dd67845.ttf") format("truetype"), url("../Poppins-Bold.beacf6c6.svg#Poppins-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-Black.3c1521c8.eot");
  src: url("../Poppins-Black.3c1521c8.eot#iefix") format("embedded-opentype"), url("../Poppins-Black.515a4ac4.woff2") format("woff2"), url("../Poppins-Black.8310bfc3.woff") format("woff"), url("../Poppins-Black.ba8c8aa4.ttf") format("truetype"), url("../Poppins-Black.01a1f21a.svg#Poppins-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-SemiBoldItalic.d8d5c81f.eot");
  src: url("../Poppins-SemiBoldItalic.d8d5c81f.eot#iefix") format("embedded-opentype"), url("../Poppins-SemiBoldItalic.8c27f7c8.woff2") format("woff2"), url("../Poppins-SemiBoldItalic.5f26be46.woff") format("woff"), url("../Poppins-SemiBoldItalic.a9199618.ttf") format("truetype"), url("../Poppins-SemiBoldItalic.6cd8e2c6.svg#Poppins-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-MediumItalic.154ba6fb.eot");
  src: url("../Poppins-MediumItalic.154ba6fb.eot#iefix") format("embedded-opentype"), url("../Poppins-MediumItalic.adc4468c.woff2") format("woff2"), url("../Poppins-MediumItalic.bf82ed60.woff") format("woff"), url("../Poppins-MediumItalic.7c349ac7.ttf") format("truetype"), url("../Poppins-MediumItalic.ea06a457.svg#Poppins-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-ExtraBoldItalic.22e7ebe5.eot");
  src: url("../Poppins-ExtraBoldItalic.22e7ebe5.eot#iefix") format("embedded-opentype"), url("../Poppins-ExtraBoldItalic.5bb4e198.woff2") format("woff2"), url("../Poppins-ExtraBoldItalic.6fbdbf82.woff") format("woff"), url("../Poppins-ExtraBoldItalic.42b1e1a6.ttf") format("truetype"), url("../Poppins-ExtraBoldItalic.daa95b8e.svg#Poppins-ExtraBoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-LightItalic.3e819938.eot");
  src: url("../Poppins-LightItalic.3e819938.eot#iefix") format("embedded-opentype"), url("../Poppins-LightItalic.5cba952d.woff2") format("woff2"), url("../Poppins-LightItalic.8a4c6af8.woff") format("woff"), url("../Poppins-LightItalic.dd01de7e.ttf") format("truetype"), url("../Poppins-LightItalic.57729b2f.svg#Poppins-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-Regular.43c1377b.eot");
  src: url("../Poppins-Regular.43c1377b.eot#iefix") format("embedded-opentype"), url("../Poppins-Regular.f69339b7.woff2") format("woff2"), url("../Poppins-Regular.566bc3d7.woff") format("woff"), url("../Poppins-Regular.23dd9cb8.ttf") format("truetype"), url("../Poppins-Regular.823c2d0a.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-Medium.b3b37ad6.eot");
  src: url("../Poppins-Medium.b3b37ad6.eot#iefix") format("embedded-opentype"), url("../Poppins-Medium.660172a9.woff2") format("woff2"), url("../Poppins-Medium.6c241562.woff") format("woff"), url("../Poppins-Medium.f43fa647.ttf") format("truetype"), url("../Poppins-Medium.6342cf03.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-ExtraLight.ab64ac65.eot");
  src: url("../Poppins-ExtraLight.ab64ac65.eot#iefix") format("embedded-opentype"), url("../Poppins-ExtraLight.ca7f2086.woff2") format("woff2"), url("../Poppins-ExtraLight.c67d39f1.woff") format("woff"), url("../Poppins-ExtraLight.83cd3291.ttf") format("truetype"), url("../Poppins-ExtraLight.f17e492d.svg#Poppins-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-ExtraLightItalic.928384c2.eot");
  src: url("../Poppins-ExtraLightItalic.928384c2.eot#iefix") format("embedded-opentype"), url("../Poppins-ExtraLightItalic.c8755e03.woff2") format("woff2"), url("../Poppins-ExtraLightItalic.1ba5d50b.woff") format("woff"), url("../Poppins-ExtraLightItalic.fb861d92.ttf") format("truetype"), url("../Poppins-ExtraLightItalic.5962c723.svg#Poppins-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-Italic.ec28debb.eot");
  src: url("../Poppins-Italic.ec28debb.eot#iefix") format("embedded-opentype"), url("../Poppins-Italic.b4257bae.woff2") format("woff2"), url("../Poppins-Italic.1059da48.woff") format("woff"), url("../Poppins-Italic.6fd8b5cd.ttf") format("truetype"), url("../Poppins-Italic.bd441b86.svg#Poppins-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-Light.bf24a2fa.eot");
  src: url("../Poppins-Light.bf24a2fa.eot#iefix") format("embedded-opentype"), url("../Poppins-Light.5fc3569c.woff2") format("woff2"), url("../Poppins-Light.a83a99e5.woff") format("woff"), url("../Poppins-Light.97414cbb.ttf") format("truetype"), url("../Poppins-Light.d37152fc.svg#Poppins-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-ExtraBold.5630cd2d.eot");
  src: url("../Poppins-ExtraBold.5630cd2d.eot#iefix") format("embedded-opentype"), url("../Poppins-ExtraBold.9cb014d8.woff2") format("woff2"), url("../Poppins-ExtraBold.707cbeb8.woff") format("woff"), url("../Poppins-ExtraBold.99df07e0.ttf") format("truetype"), url("../Poppins-ExtraBold.52c5c82e.svg#Poppins-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-SemiBold.84f0d999.eot");
  src: url("../Poppins-SemiBold.84f0d999.eot#iefix") format("embedded-opentype"), url("../Poppins-SemiBold.dcf952bd.woff2") format("woff2"), url("../Poppins-SemiBold.9783be9f.woff") format("woff"), url("../Poppins-SemiBold.45b67b1d.ttf") format("truetype"), url("../Poppins-SemiBold.138fe7b2.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-ThinItalic.16154966.eot");
  src: url("../Poppins-ThinItalic.16154966.eot#iefix") format("embedded-opentype"), url("../Poppins-ThinItalic.6c4d5b7f.woff2") format("woff2"), url("../Poppins-ThinItalic.aa4985d2.woff") format("woff"), url("../Poppins-ThinItalic.8f3c442e.ttf") format("truetype"), url("../Poppins-ThinItalic.9d4c26b2.svg#Poppins-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("../Poppins-Thin.f5168b38.eot");
  src: url("../Poppins-Thin.f5168b38.eot#iefix") format("embedded-opentype"), url("../Poppins-Thin.ed189074.woff2") format("woff2"), url("../Poppins-Thin.595c2c30.woff") format("woff"), url("../Poppins-Thin.6971670d.ttf") format("truetype"), url("../Poppins-Thin.be9aec29.svg#Poppins-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html {
  background: var(--grey-white);
  overflow: hidden;
}

div {
  -webkit-user-select: none;
  user-select: none;
}

.monaco-editor div {
  -webkit-user-select: auto;
  user-select: auto;
}

div[role="block-grab"] {
  cursor: grab;
}

div[role="block-grabbing"] {
  cursor: grabbing;
}

div[role="button"], button {
  cursor: pointer;
}

input, textarea, select {
  outline: none;
  padding: 0;
}

select {
  -webkit-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=") 100% / 1.5em no-repeat;
  border-radius: 5px;
  padding-left: .2rem;
  padding-right: 1.5rem;
  transition: all .3s ease-in-out;
  border: 1px solid #0000 !important;
}

select:hover {
  -webkit-appearance: default;
  appearance: default;
  color: #fff;
  background: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=") no-repeat 100% 50%, var(--vintage-code);
  background-size: 1.5em;
  border: 1px solid #0000;
}

.splash-container {
  grid-col: 1;
  max-height: calc(100vh - 65px);
  padding: 2rem;
  overflow-y: auto;
}

.vertical-splash-separator {
  background-color: var(--digital-grey-V);
  width: 1px;
  height: 60%;
}

.flex-direction-splash-buttons {
  flex-direction: column;
}

@media (width <= 1120px) {
  .vertical-splash-separator {
    display: none;
  }

  .flex-direction-splash-buttons {
    flex-direction: row;
  }
}

.scrollable-welcome {
  height: 450px;
}

@media (width <= 895px) {
  .scrollable-welcome {
    height: 100%;
  }
}

.ResizeHandleOuter {
  background: var(--whitesmoke);
  -webkit-user-select: none;
  user-select: none;
  flex: 0 0 10px;
  position: relative;
}

.ResizeHandleOuter--vertical {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.ResizeHandleOuter--horizontal {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

.ResizeHandleOuter[data-resize-handle-active] {
  background: var(--light-blue);
  transition: background-color .2s linear;
}

.ResizeHandleInner {
  transition: background-color .2s linear;
  position: absolute;
  inset: 0 !important;
}

.Icon {
  width: 1em;
  height: 1em;
  position: absolute;
  top: calc(50% - .5rem);
  left: calc(50% - .5rem);
}

.Icon-horizontal {
  transform: rotate(90deg);
}

.horus-scroll {
  border-radius: .25rem;
  overflow-y: auto;
}

.modal-body {
  flex: auto !important;
  padding: 0 !important;
  position: relative !important;
}

.modal-content {
  border-radius: 15px !important;
  transition: height 1s ease-in-out !important;
  overflow: auto !important;
}

.backdrop-blur {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px) !important;
}

.root {
  height: 100vh;
}

.normal-container {
  border: 1px solid var(--vintage-code);
  background-color: #f5f5f5;
  border-radius: 15px;
  height: 100%;
  padding: .5rem;
}

.chonky-infoContainer {
  display: none !important;
}

.sticky-app-header {
  grid-area: 1 / 1;
  height: 65px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.bsc-btn {
  border: none;
  border: 2px solid var(--gradient-blue);
  background: var(--gradient-blue);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 1;
  text-align: center;
  border-radius: 20px;
  width: max-content;
  min-width: 230px;
  margin: 0 auto;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.bsc-btn:disabled {
  color: #fff;
  cursor: not-allowed;
  background: #ccc;
  border: 2px solid #ccc;
}

.flow-status {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.flow-status > svg {
  margin: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.blurred-modal-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.blurred-modal-content {
  -webkit-backdrop-filter: blur(100px);
  background: #fffc;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 5px 5px 100px #0003;
  backdrop-filter: blur(100px) !important;
}

.index-module_lineSelectable__1-mwt {
  -webkit-user-select: text;
  user-select: text;
}
/*# sourceMappingURL=index.32968c68.css.map */
